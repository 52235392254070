.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

}

.App-link {
  color: #09d3ac;
}

.bc-header{
  margin-bottom: 2rem;
}

.bc-column-text{
  text-align: center;
}

.bc-text{
  font-size: large;
}

.bc-project-card{
  margin-bottom: 2rem;
}

p{
  font-size: larger;
}

strong{
  font-size: larger;
}

html {
  scroll-behavior: smooth;
}